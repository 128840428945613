import { InfoWindow, Map, useMap } from '@vis.gl/react-google-maps';
import { WorkOrder } from '@/hooks/useWorkOrders';
import { useEntitiesById } from '@/hooks/useEntities';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Marker,
  MarkerClusterer,
  MarkerUtils,
} from '@googlemaps/markerclusterer';
import { cn } from '@/lib/utils';
import { Entity } from '@/types/entities';
import { Spinner } from './Spinner';
import Button from './core/Button';
import { useNavigate } from '@tanstack/react-router';
import { StateName } from './StateName';
import { FormattedMessage } from 'react-intl';
import WorkOrderMapFilter from './WorkOrderMapFilter';

interface Props {
  workorders: WorkOrder[];
}

interface EntityWithWorkOrders extends Entity {
  workorders: WorkOrder[];
}

export function WorkOrderMap({ workorders }: Props) {
  const [filteredWorkorders, setFilteredWorkorders] = useState<WorkOrder[]>([]);

  const effectiveWorkorders = useMemo(() => {
    return filteredWorkorders.length === 0 ? workorders : filteredWorkorders;
  }, [filteredWorkorders, workorders]);

  // generate list of unique entity ids
  const entityIds = useMemo(
    () =>
      effectiveWorkorders.length > 0
        ? [
            ...new Set(
              effectiveWorkorders.flatMap((workorder) => workorder.entities),
            ),
          ]
        : undefined,
    [effectiveWorkorders],
  );

  const entities = useEntitiesById(entityIds);

  const entitiesWithWorkOrders = useMemo(
    () =>
      (entities.data || []).map((entity) => ({
        ...entity,
        workorders: effectiveWorkorders.filter((workorder) =>
          workorder.entities.includes(entity.id),
        ),
      })),
    [entities.data, effectiveWorkorders],
  );

  return (
    <>
      <div className="size-full">
        {effectiveWorkorders.length === 0 ? (
          <div className="w-full px-2 sm:px-8 md:px-16">
            <i>
              <FormattedMessage defaultMessage="Du har inga accepterade arbetsordrar" />
            </i>
          </div>
        ) : (
          <>
            <WorkOrderMapFilter
              workorders={workorders}
              value={filteredWorkorders}
              onChange={setFilteredWorkorders}
            />
            <Map
              className="size-full"
              mapId="ed6ae4b48b089819"
              defaultCenter={{
                lat: 59.2718832,
                lng: 15.208291,
              }}
              defaultZoom={6}
              gestureHandling="greedy"
              keyboardShortcuts={false}
              streetViewControl={false}
              fullscreenControl={false}
            >
              {entities.isLoading && (
                <div className="absolute inset-0 flex flex-col items-center justify-center bg-black/10">
                  <Spinner />
                </div>
              )}
              <Markers entities={entitiesWithWorkOrders} />
            </Map>
          </>
        )}
      </div>
    </>
  );
}

interface MarkersProps {
  entities: EntityWithWorkOrders[];
}

interface MarkerLookup {
  [key: string]: Marker;
}

function Markers({ entities }: MarkersProps) {
  const navigate = useNavigate();
  const map = useMap();
  const [markers, setMarkers] = useState<MarkerLookup>({});
  const clusterer = useRef<MarkerClusterer | null>(null);

  // Initialize the marker clusterer
  useEffect(() => {
    if (!map) {
      return;
    }
    if (!clusterer.current) {
      clusterer.current = new MarkerClusterer({
        map,
        algorithmOptions: { maxZoom: 14 },
        renderer: {
          render({ count, position }, stats) {
            // change color if this cluster has more markers than the mean cluster
            const className = cn(
              'opacity-80 hover:opacity-100 cursor-pointer transition-opacity duration-200 ease-in-out',
              count > Math.max(10, stats.clusters.markers.mean)
                ? 'fill-red-500'
                : 'fill-orange-500',
            );

            // create svg literal with fill color
            const svg = `<svg class="${className}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" width="50" height="50">
<circle cx="120" cy="120" r="70" />
<text x="50%" y="50%" style="fill:#fff" text-anchor="middle" font-size="50" dominant-baseline="middle" font-family="roboto,arial,sans-serif">${count}</text>
</svg>`;
            const title = `Cluster of ${count} markers`,
              // adjust zIndex to be above other markers
              zIndex = count;
            if (MarkerUtils.isAdvancedMarkerAvailable(map)) {
              // create cluster SVG element
              const parser = new DOMParser();
              const svgEl = parser.parseFromString(
                svg,
                'image/svg+xml',
              ).documentElement;
              svgEl.setAttribute('transform', 'translate(0 25)');
              const clusterOptions = {
                map,
                position,
                zIndex,
                title,
                content: svgEl,
              };
              return new google.maps.marker.AdvancedMarkerElement(
                clusterOptions,
              );
            }
            const clusterOptions = {
              position,
              zIndex,
              title,
              icon: {
                url: `data:image/svg+xml;base64,${btoa(svg)}`,
                anchor: new google.maps.Point(25, 25),
              },
            };
            return new google.maps.marker.AdvancedMarkerElement(clusterOptions);
          },
        },
      });
    }
  }, [map]);

  // Update the markers
  useEffect(() => {
    if (!map || !clusterer.current) {
      return;
    }

    clusterer.current.clearMarkers();
    clusterer.current.addMarkers(Object.values(markers), false);

    const bounds = new google.maps.LatLngBounds();
    for (const marker of Object.values(markers)) {
      bounds.extend(MarkerUtils.getPosition(marker));
    }
    map.fitBounds(bounds);
  }, [map, markers]);

  // Initialize markers
  useEffect(() => {
    const newMarkers: MarkerLookup = {};
    entities.forEach((entity) => {
      const marker = new google.maps.marker.AdvancedMarkerElement({
        position: entity.location,
        map: null,
        title: entity.full_name,
      });
      marker.addListener('click', () => {
        setShowInfo(entity);
      });
      newMarkers[entity.id] = marker;
    });
    setMarkers(newMarkers);
  }, [entities, map]);

  const [showInfo, setShowInfo] = useState<EntityWithWorkOrders>();
  const offset = new google.maps.Size(0, -40);

  return (
    <>
      {showInfo && (
        <InfoWindow
          position={showInfo.location}
          pixelOffset={offset}
          onCloseClick={() => setShowInfo(undefined)}
          minWidth={300}
          disableAutoPan={false}
          shouldFocus={true}
          maxWidth={500}
        >
          <h3>
            {showInfo.full_name}
            <span className="ml-4">
              {showInfo.entity_group}-{showInfo.stop_letter}
            </span>
          </h3>
          <h5 className="text-text-400">
            <FormattedMessage defaultMessage="Arbetsordrar" />
          </h5>
          <ul className="mt-2 flex flex-col gap-2">
            {showInfo.workorders.map((workorder) => (
              <li key={workorder.id}>
                <Button
                  className="w-full"
                  onClick={() =>
                    navigate({
                      to: '/workorders/$workorderId',
                      params: { workorderId: workorder.id },
                    })
                  }
                >
                  {workorder.title}{' '}
                  <span className="italic opacity-70">
                    #{workorder.title_suffix}
                  </span>
                  <span>
                    {' '}
                    (<StateName state={workorder.state} />)
                  </span>
                </Button>
              </li>
            ))}
          </ul>
        </InfoWindow>
      )}
    </>
  );
}
