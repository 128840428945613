import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Dialog, DialogContent } from '../dialog';
import Button from '../core/Button';
import { FormattedMessage } from 'react-intl';
import {
  IconCamera,
  IconMediaImage,
  IconXmark,
  IconTrashSolid,
  IconNavArrowLeft,
  IconNavArrowRight,
} from '@allbin/icons';
import { useConfig } from '@/hooks/useConfig';
import { useCreateAttachment } from '@/hooks/useAttachments';
import { ApiAttachment } from '@allbin/mobilix-api-client';
import { Spinner } from '../Spinner';
import IconButton from '../core/IconButton';
import { ImageRowProps } from './EntityPropertyList';

interface Props {
  children: ReactNode;
}

interface ImagePickerOptions {
  currentImage?: string;
  currentImageKey?: string;
  images?: ImageRowProps[];
  onChange?: (
    attachment: ApiAttachment | null,
    targetFieldName: string,
  ) => void;
}

interface ImagePickerContextData {
  open: boolean;
  options?: ImagePickerOptions;
  setOpen: (open: boolean, options?: ImagePickerOptions) => void;
  resetImages: () => void;
}

export const ImagePickerContext = createContext<ImagePickerContextData>({
  open: false,
  setOpen: () => {},
  resetImages: () => {},
});

export function ImagePickerProvider({ children }: Props) {
  const { cdnUrl } = useConfig();
  const createAttachment = useCreateAttachment();

  const [open, setOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const [imageKey, setImageKey] = useState('');
  const [currentOptions, setCurrentOptions] =
    useState<ImagePickerOptions | null>(null);

  const galleryInputRef = useRef<HTMLInputElement>(null);
  const cameraInputRef = useRef<HTMLInputElement>(null);
  const imagesRef = useRef<ImageRowProps[]>([]);

  useEffect(() => {
    if (open) {
      return;
    }
  }, [open]);

  const handleOpen = useCallback<ImagePickerContextData['setOpen']>(
    (open, options) => {
      setOpen(open);

      if (!options || !open) {
        return;
      }

      const { images, currentImageKey } = options;
      setCurrentOptions(options);

      if (Array.isArray(images)) {
        if (!imagesRef.current || imagesRef.current?.length === 0) {
          imagesRef.current = images;
        }

        if (currentImageKey) {
          setImageKey(currentImageKey);
          const index = images.findIndex(
            (img) => img.propertyKey === currentImageKey,
          );
          setImageIndex(index !== -1 ? index : 0);
        }
      }
    },
    [],
  );

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onInput = useCallback((source: 'gallery' | 'camera') => {
    switch (source) {
      case 'gallery':
        return galleryInputRef.current?.click();
      case 'camera':
        return cameraInputRef.current?.click();
    }
  }, []);

  const handleSave = useCallback(
    (attachment: ApiAttachment | null) => {
      currentOptions?.onChange?.(attachment, imageKey);
      handleClose();
    },
    [currentOptions, imageKey, handleClose],
  );

  const handleImageChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement> | null) => {
      const image = event?.target.files?.[0];

      try {
        let created: ApiAttachment | null = null;
        if (image) {
          created = await createAttachment.mutateAsync(image);
        }

        const newImages = imagesRef.current ? [...imagesRef.current] : [];
        newImages[imageIndex] = {
          ...newImages[imageIndex],
          imageId: created?.id || null,
        };

        imagesRef.current = newImages;

        handleSave(created);

        setImageKey((imageKey) => imageKey + '1');
      } catch (e) {
        // FIXME: popup error
        console.error('Error creating attachment', e);
      }
    },
    [createAttachment, imageIndex, handleSave],
  );

  const handleRemoveImage = useCallback(() => {
    handleImageChange(null);
  }, [handleImageChange]);

  const isLoading = useMemo(() => {
    return createAttachment.isPending;
  }, [createAttachment.isPending]);

  const prev = useCallback(() => {
    if (imagesRef.current?.length) {
      setImageIndex((prevIndex) => {
        const newIndex =
          (prevIndex - 1 + imagesRef.current!.length) %
          imagesRef.current!.length;
        setImageKey(imagesRef.current![newIndex].propertyKey);
        return newIndex;
      });
    }
  }, []);

  const next = useCallback(() => {
    if (imagesRef.current?.length) {
      setImageIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % imagesRef.current!.length;
        setImageKey(imagesRef.current![newIndex].propertyKey);
        return newIndex;
      });
    }
  }, []);

  const resetImages = useCallback(() => {
    imagesRef.current = [];
    setImageIndex(0);
    setCurrentOptions(null);
  }, []);

  return (
    <ImagePickerContext.Provider
      value={{ open, setOpen: handleOpen, resetImages }}
    >
      <>
        {children}
        <Dialog open={open} onOpenChange={handleClose}>
          <DialogContent className="min-h-full min-w-full overflow-auto border-0 bg-slate-950/20 p-0">
            <div className="flex max-h-full flex-col justify-center gap-2 overflow-auto">
              <div className="flex items-center justify-center">
                {isLoading && <Spinner className="size-16" />}
                {!isLoading && (
                  <>
                    <div className="fixed inset-4 left-0 top-0 z-30 flex size-full items-center justify-between bg-background-50 px-4 pt-4">
                      <div className="absolute inset-4 flex items-center justify-between px-4">
                        <IconButton
                          variant="filled"
                          round
                          size="lg"
                          className="z-10 shadow-xl"
                          onClick={prev}
                          icon={<IconNavArrowLeft className="size-8" />}
                        />
                        <IconButton
                          variant="filled"
                          round
                          size="lg"
                          className="z-10 shadow-xl"
                          onClick={next}
                          icon={<IconNavArrowRight className="size-8" />}
                        />
                      </div>
                      <div className="relative flex size-full flex-col items-center">
                        <div
                          className="relative flex w-full grow cursor-pointer items-center justify-center overflow-hidden"
                          onClick={handleClose}
                        >
                          <span className="absolute top-0 z-10 flex justify-center rounded-md bg-gray-700 p-1 px-2 text-gray-50">
                            {imagesRef.current[imageIndex]?.name}
                          </span>
                          {imagesRef.current.length > 0 &&
                          imagesRef.current[imageIndex]?.imageId !== null &&
                          !isLoading ? (
                            <img
                              className="size-full object-contain"
                              src={`${cdnUrl}/${imagesRef.current?.[imageIndex].imageId}/lg`}
                            />
                          ) : (
                            <div className="flex size-full flex-1 items-center justify-center gap-2 bg-background-50 text-gray-600">
                              <IconMediaImage className="size-6" />
                              {imagesRef.current[imageIndex]?.name + ' saknas'}
                            </div>
                          )}
                        </div>
                        <div className="flex h-20 w-full items-center justify-center gap-4 bg-background-50 p-4">
                          <Button
                            className="flex-1"
                            variant="filled"
                            color="red"
                            onClick={handleRemoveImage}
                            startIcon={<IconTrashSolid />}
                          >
                            <FormattedMessage defaultMessage="Ta bort bild" />
                          </Button>
                          <Button
                            className="flex-1"
                            variant="filled"
                            onClick={() => onInput('camera')}
                            startIcon={<IconCamera />}
                          >
                            <FormattedMessage defaultMessage="Ta bild" />
                          </Button>
                          <Button
                            className="flex-1"
                            variant="filled"
                            onClick={() => onInput('gallery')}
                            startIcon={<IconMediaImage />}
                          >
                            <FormattedMessage defaultMessage="Välj från galleri" />
                          </Button>
                        </div>
                      </div>
                    </div>
                    <IconButton
                      variant="filled"
                      round
                      size="lg"
                      icon={<IconXmark className="size-6" />}
                      className="absolute right-4 top-4 z-50"
                      onClick={handleClose}
                    />
                  </>
                )}
              </div>
              <input
                className="hidden"
                ref={galleryInputRef}
                type="file"
                accept="image/*"
                onChange={handleImageChange}
              />
              <input
                className="hidden"
                ref={cameraInputRef}
                type="file"
                accept="capture=camera,image/*"
                capture="environment"
                onChange={handleImageChange}
              />
            </div>
          </DialogContent>
        </Dialog>
      </>
    </ImagePickerContext.Provider>
  );
}
