import { EntityProps } from '@/types/entities';
import { ApiEntitySchema } from '@allbin/mobilix-api-client';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { IconMediaImage } from '@allbin/icons';
import { Map, AdvancedMarker } from '@vis.gl/react-google-maps';
import { Control } from 'react-hook-form';
import { FormValues } from './EntityPropertyList';
import { ImageGallery } from '../ImageGallery';

interface EntityPropertyListProps {
  entityId: string;
  properties: EntityProps;
  schema: ApiEntitySchema;
  control?: Control<FormValues>;
}

export interface EntityGalleryImageProps {
  entityId: string;
  name: string;
  value: string | null;
}

interface EntityLocationProps {
  x: number;
  y: number;
}

export function EntityGalleryMap({
  properties,
  schema,
  entityId,
}: EntityPropertyListProps) {
  const images = useMemo(() => {
    return schema.definition.properties
      .filter((property) => property.type === 'photo')
      .map((property) => ({
        entityId,
        name: property.name,
        value: properties[property.key] ?? null,
      }));
  }, [schema.definition.properties, properties, entityId]);

  const location = useMemo(() => {
    const locationProp = schema.definition.properties.find(
      (property) => property.type === 'location',
    );
    if (locationProp && properties[locationProp.key]) {
      const [x, y] = properties[locationProp.key] as [number, number];
      return { x, y };
    }
    return null;
  }, [schema.definition.properties, properties]);

  return (
    <div className="mb-2 flex min-h-52 w-full gap-2">
      <div className="relative flex-1">
        <ImageGallery images={images as EntityGalleryImageProps[]} />
      </div>
      <div className="flex-1 border border-primary-100 bg-slate-400">
        <EntityMap location={location} />
      </div>
    </div>
  );
}

function EntityMap({ location }: { location: EntityLocationProps | null }) {
  if (!location?.x || !location?.y) {
    return (
      <div className="flex h-full flex-1 items-center justify-center gap-2 bg-gray-200 text-gray-600">
        <IconMediaImage className="size-6" />
        <FormattedMessage defaultMessage="'Plats' saknas" />
      </div>
    );
  }

  return (
    <Map
      className="size-full"
      mapId="ed6ae4b48b089819"
      defaultCenter={{
        lat: location?.y || 59.2718832,
        lng: location?.x || 15.208291,
      }}
      defaultZoom={15}
      gestureHandling="greedy"
      keyboardShortcuts={false}
      disableDefaultUI
    >
      {location && (
        <AdvancedMarker
          position={{
            lat: location.y,
            lng: location.x,
          }}
        />
      )}
    </Map>
  );
}
