import { useVirtualizer } from '@tanstack/react-virtual';
import { EntityGroup } from '../types/entities';
import { cn } from '../utils/classnames';
import { useCallback, useMemo, useRef } from 'react';
import { useNavigate } from '@tanstack/react-router';
import { collator } from '../utils/collator';
import IconButton from './core/IconButton';
import { IconMediaImage, IconLink, IconArrowRight } from '@allbin/icons';
import { useWorkOrders } from '@/hooks/useWorkOrders';
import { FormattedMessage } from 'react-intl';
import { DateTime } from 'luxon';
import { Tooltip } from 'react-tooltip';
import Button from './core/Button';

export function EntityGroupList({ groups }: { groups: EntityGroup[] }) {
  const parentRef = useRef<HTMLDivElement | null>(null);

  const rowVirtualizer = useVirtualizer({
    count: groups.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 64,
  });

  const items = rowVirtualizer.getVirtualItems();

  return (
    <>
      <div className="min-h-8 w-full px-2 sm:px-8 md:px-16 ">
        <EntityGroupHeader />
      </div>
      <div
        ref={parentRef}
        className="size-full overflow-y-auto px-2 sm:px-8 md:px-16 "
      >
        <ul
          className="relative flex w-full grow flex-col"
          style={{ height: rowVirtualizer.getTotalSize() }}
        >
          <div
            className="absolute left-0 top-0 w-full"
            style={{
              transform: `translateY(${items[0]?.start}px)`,
            }}
          >
            {rowVirtualizer.getVirtualItems().map((item) => (
              <li
                key={item.key}
                ref={rowVirtualizer.measureElement}
                data-index={item.index}
                className={cn(item.index % 2 === 0 && 'bg-background-100')}
              >
                <EntityGroupRow group={groups[item.index]} />
              </li>
            ))}
          </div>
        </ul>
      </div>
    </>
  );
}

interface EntityGroupRowProps {
  group: EntityGroup;
}

interface EntityWorkOrderStatus {
  id: string;
  entityId: string;
  title: string;
  state: string | null;
}

function EntityGroupRow({ group }: EntityGroupRowProps) {
  const navigate = useNavigate();
  const workOrders = useWorkOrders();

  const orderedEntities = useMemo(
    () =>
      group.entities.sort((a, b) =>
        collator.compare(a.stop_letter, b.stop_letter),
      ),
    [group.entities],
  );

  const entityWorkOrderStatus = useMemo(() => {
    if (!workOrders.data) return null;

    const entityIdSet = new Set(orderedEntities.map((entity) => entity.id));

    const selectedEntities: EntityWorkOrderStatus[] = [];
    const now = DateTime.now();

    for (const workOrder of workOrders.data) {
      for (const entityId of workOrder.entities) {
        if (entityIdSet.has(entityId)) {
          let state: string | null = null;
          if (
            workOrder.due_at instanceof DateTime &&
            workOrder.due_at < now &&
            (workOrder.state === 'created' || workOrder.state === 'accepted')
          ) {
            state = 'late';
          } else if (workOrder.state === 'created') {
            state = 'created';
          } else if (workOrder.state === 'accepted') {
            state = 'accepted';
          }

          selectedEntities.push({
            id: workOrder.id,
            entityId,
            title: workOrder.title,
            state,
          });
        }
      }
    }

    return selectedEntities.length > 0 ? selectedEntities : null;
  }, [workOrders.data, orderedEntities]);

  const hasImages = useMemo(() => {
    return orderedEntities.some((entity) =>
      // FIX: remove hard coded inventories
      ['inventory.54', 'inventory.55', 'inventory.56', 'inventory.57'].some(
        (prop) => Object.prototype.hasOwnProperty.call(entity.properties, prop),
      ),
    );
  }, [orderedEntities]);

  return (
    <div className="flex min-h-16 flex-col gap-4 p-4 sm:flex-row sm:items-center sm:py-2">
      <div className="min-w-24">{group.entity_group}</div>
      <div className="min-w-56 text-nowrap">{group.full_name}</div>
      <div className="flex grow flex-wrap items-center gap-1">
        {entityWorkOrderStatus?.map((workorder) => (
          <StateIndicator
            key={workorder.id + workorder.entityId}
            state={workorder.state}
            stopLetter={
              orderedEntities.find((entity) => entity.id === workorder.entityId)
                ?.stop_letter
            }
            workOrderTitle={workorder.title}
            workOrderId={workorder.id}
          />
        ))}
      </div>
      <div className="flex flex-nowrap items-center gap-2 sm:justify-end">
        {orderedEntities.map((entity) => (
          <IconButton
            key={entity.id}
            variant="filled"
            color="entity"
            icon={entity.stop_letter}
            round
            className="!font-medium"
            onClick={() =>
              navigate({
                to: '/entities/$entityId',
                params: { entityId: entity.id },
              })
            }
          />
        ))}
        <div className="ml-2 min-w-5" title="Har bild">
          {hasImages && <IconMediaImage className="size-5 text-text-600" />}
        </div>
      </div>
    </div>
  );
}

function EntityGroupHeader() {
  return (
    <div className="sticky top-0 z-20 hidden min-h-8 flex-row items-center gap-4 rounded-md bg-background-200 px-4 py-2 text-sm font-semibold sm:flex">
      <div className="min-w-24">
        <FormattedMessage defaultMessage="ID" />
      </div>
      <div className="min-w-56">
        <FormattedMessage defaultMessage="Namn" />
      </div>
      <div className="flex grow">
        <FormattedMessage defaultMessage="Arbetsordrar" />
      </div>
      <div className="flex justify-end">
        <FormattedMessage defaultMessage="Stopp / Bilder" />
      </div>
    </div>
  );
}

interface StateIndicatorProps {
  state: string | null;
  stopLetter: string | undefined;
  workOrderTitle: string | null;
  workOrderId: string;
}

const stateConfig: Record<string, { title: string; bgColor: string }> = {
  created: {
    title: 'Arbetsorder inlagd',
    bgColor: 'bg-blue-500 hover:bg-blue-600',
  },
  accepted: {
    title: 'Påbörjad',
    bgColor: 'bg-yellow-500 hover:bg-yellow-600',
  },
  late: { title: 'Försenad', bgColor: 'bg-red-500 hover:bg-red-600' },
};

function StateIndicator({
  state,
  stopLetter,
  workOrderTitle,
  workOrderId,
}: StateIndicatorProps) {
  const navigate = useNavigate();

  const goToWorkOrder = useCallback(() => {
    navigate({
      to: '/workorders/$workorderId',
      params: { workorderId: workOrderId },
    });
  }, [navigate, workOrderId]);

  if (!state || !stateConfig[state]) return null;

  const { bgColor } = stateConfig[state];
  const tooltipId = `tooltip-${workOrderId}-${state}`;

  return (
    <>
      <Tooltip id={tooltipId} delayHide={1} clickable openOnClick>
        <div
          className="flex cursor-pointer items-center justify-center gap-2"
          onClick={goToWorkOrder}
        >
          <IconLink className="size-4" />
          {stateConfig[state].title}
          {workOrderTitle && (
            <>
              {' '}
              <IconArrowRight className="inline size-3" /> {workOrderTitle}
            </>
          )}
        </div>
      </Tooltip>
      <Button
        data-tooltip-id={tooltipId}
        variant="ghost"
        round
        size="md"
        className={cn(
          bgColor,
          'text-[12px]',
          'text-white',
          'h-[36px]',
          'w-[36px]',
          'p-0',
        )}
      >
        {stopLetter}
      </Button>
    </>
  );
}
