import { cn } from '@/lib/utils';
import { ReactNode } from 'react';

interface Props {
  title?: ReactNode;
  subtitle?: ReactNode;
  action?: ReactNode;
  footer?: ReactNode;
}

export function PageHeader({ title, subtitle, action, footer }: Props) {
  return (
    <header
      className={cn(
        'sticky top-0 z-10 flex flex-col gap-4 bg-background-50 pt-8',
        !footer && 'pb-8',
      )}
    >
      <div className="flex flex-col justify-between sm:flex-row sm:items-end">
        <div className="mb-5 flex flex-col gap-2 sm:mb-0">
          {title && <h2>{title}</h2>}
          {subtitle && <h3>{subtitle}</h3>}
        </div>
        {action}
      </div>
      {footer}
    </header>
  );
}
