import { UseControllerProps, useController } from 'react-hook-form';
import { FormValues } from '../EntityPropertyList';
import Input from '@/components/core/Input';
import React, { useCallback, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';

export function NumberInput(props: UseControllerProps<FormValues>) {
  const intl = useIntl();
  const { field } = useController(props);
  const inputRef = useRef<HTMLInputElement>(null);

  const value = useMemo(() => {
    return field.value as number | null;
  }, [field.value]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (e.target.value === '') {
        return field.onChange(null);
      } else {
        field.onChange(Number(e.target.value));
      }
    },
    [field],
  );

  const handleOnKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        inputRef.current?.blur();
      }
    },
    [],
  );

  return (
    <Input
      ref={inputRef}
      id={field.name}
      type="text"
      inputMode="numeric"
      pattern="[0-9]*"
      enterKeyHint="done"
      onKeyDown={handleOnKeyDown}
      value={value?.toString() ?? ''}
      onChange={handleChange}
      placeholder={intl.formatMessage({ defaultMessage: 'Saknas' })}
    />
  );
}
