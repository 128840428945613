import { EntityGalleryImageProps } from './EntityProp/EntityGalleryMap';
import { useConfig } from '../hooks/useConfig';
import { useCallback, useState } from 'react';
import { IconMediaImage } from '@allbin/icons';
import IconButton from './core/IconButton';
import { IconNavArrowLeft, IconNavArrowRight, IconXmark } from '@allbin/icons';
import { cn } from '@/utils/classnames';

export function ImageGallery({
  images,
}: {
  images: EntityGalleryImageProps[];
}) {
  const [isOpen, setIsOpen] = useState(false);

  const { cdnUrl } = useConfig();
  const [currentIndex, setCurrentIndex] = useState(
    () => images.findIndex((image) => image.name === 'Plats-bild') || 0,
  );

  const currentImage = images[currentIndex];

  const prev = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1,
    );
  }, [images.length]);

  const next = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  }, [images.length]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <>
      <div key={currentImage.entityId} className="h-full">
        {currentImage.value === null ? (
          <div
            className="flex h-full flex-1 cursor-pointer items-center justify-center gap-2 bg-gray-200 text-gray-600"
            onClick={() => setIsOpen(true)}
          >
            <IconMediaImage className="size-6" />
            {currentImage.name + ' saknas'}
          </div>
        ) : (
          <img
            src={`${cdnUrl}/${images[currentIndex].value}/sm`}
            alt={images[currentIndex].name}
            className="size-full cursor-pointer object-cover"
            onClick={() => setIsOpen(true)}
          />
        )}
        <span className="absolute left-2 top-2 rounded-md bg-gray-800 p-1 px-2 text-sm text-gray-50">
          {images[currentIndex].name}
        </span>
      </div>
      {isOpen && (
        <div className="fixed inset-4 left-0 top-0 z-30 flex size-full items-center justify-between bg-slate-950/90">
          <div className="absolute inset-4 flex items-center justify-between">
            <IconButton
              variant="filled"
              round
              size="lg"
              icon={<IconNavArrowLeft className="size-8" />}
              className="z-10 shadow-lg"
              onClick={prev}
            />
            <IconButton
              variant="filled"
              round
              size="lg"
              icon={<IconNavArrowRight className="size-8" />}
              className="z-10 shadow-lg"
              onClick={next}
            />
          </div>
          <div className="relative flex size-full items-center">
            <div
              className="relative aspect-video w-full cursor-pointer"
              onClick={handleClose}
            >
              <div className="absolute left-0 top-0 flex w-full justify-center p-2">
                <span className="rounded-md bg-gray-700 p-1 px-2 text-gray-50">
                  {currentImage.name}
                </span>
              </div>
              {currentImage.value ? (
                <img src={`${cdnUrl}/${currentImage.value}/lg`} />
              ) : (
                <div className="flex size-full flex-1 items-center justify-center gap-2 bg-gray-200 text-gray-600">
                  <IconMediaImage className="size-6" />
                  {currentImage.name + ' saknas'}
                </div>
              )}
            </div>
          </div>
          <IconButton
            variant="filled"
            round
            size="lg"
            icon={<IconXmark className="size-6" />}
            className="absolute right-4 top-4 z-10"
            onClick={handleClose}
          />
          <div className="absolute bottom-4 z-10 mx-auto flex w-full items-center justify-center gap-4 rounded-full bg-gray-800/20 px-4 py-2 backdrop-blur-sm">
            {images.map((image, index) => (
              <button
                key={image.name}
                className={cn(
                  'z-10 size-4 rounded-full border-2 border-white shadow',
                  index === currentIndex && 'bg-white',
                )}
                onClick={() => {
                  setCurrentIndex(index);
                }}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
}
