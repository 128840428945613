import { UseControllerProps, useController } from 'react-hook-form';
import { FormValues } from '../EntityPropertyList';
import { useConfig } from '@/hooks/useConfig';
import { useCallback } from 'react';
import Button from '@/components/core/Button';
import { FormattedMessage } from 'react-intl';
import { ApiAttachment } from '@allbin/mobilix-api-client';
import { useImagePicker } from '@/hooks/useImagePicker';
import { UseFormSetValue } from 'react-hook-form';
import { useImagesFormStore } from '@/hooks/useImagesFormStore';

export function ImageInput({
  setValue,
  ...props
}: UseControllerProps<FormValues> & {
  setValue: UseFormSetValue<FormValues>;
}) {
  const {
    field: { value },
  } = useController(props);

  const { cdnUrl } = useConfig();
  const { setOpen } = useImagePicker();
  const storedImages = useImagesFormStore((state) => state.images);

  const handleOnChange = useCallback(
    (attachment: ApiAttachment | null, targetFieldName: string) => {
      if (targetFieldName) {
        const newValue = attachment ? attachment.id : null;
        if (setValue) {
          setValue(targetFieldName, newValue, { shouldValidate: true });
        } else {
          console.error('New value not found');
        }
      } else {
        console.error('Target field not found');
      }
    },
    [setValue],
  );

  const onClick = useCallback(() => {
    if (value) {
      setOpen(true, {
        currentImage: value as string,
        currentImageKey: props.name,
        images: storedImages,
        onChange: handleOnChange,
      });
    } else {
      setOpen(true, {
        currentImageKey: props.name,
        images: storedImages,
        onChange: handleOnChange,
      });
    }
  }, [value, setOpen, handleOnChange, storedImages, props.name]);

  if (!value) {
    return (
      <Button onClick={onClick}>
        <FormattedMessage defaultMessage="Lägg till bild" />
      </Button>
    );
  }

  return (
    <button className="" onClick={onClick} type="button">
      <img src={`${cdnUrl}/${value}/sm`} />
    </button>
  );
}
