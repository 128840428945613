import { WorkOrder } from '../hooks/useWorkOrders';
import { EntityName } from './EntityName';
import { Spinner } from './Spinner';
import { FormattedMessage } from 'react-intl';
import { UserName } from './UserName';
import { IconGoogleDocs } from '@allbin/icons';
import { ListButton } from './ListButton';
import { useNavigate } from '@tanstack/react-router';

interface WorkOrderListProps {
  workorders?: WorkOrder[];
  isLoading?: boolean;
  showAssignee?: boolean;
}

export function WorkOrderList({ workorders, isLoading }: WorkOrderListProps) {
  if (isLoading) {
    return (
      <div className="mt-16 flex w-full justify-center">
        <Spinner />
      </div>
    );
  }

  if (!workorders || workorders.length === 0) {
    return (
      <i>
        <FormattedMessage defaultMessage="Du har inga accepterade arbetsordrar" />
      </i>
    );
  }

  return (
    <div className="flex w-full flex-col gap-2">
      {workorders.map((workorder) => (
        <WorkOrderItem key={workorder.id} workorder={workorder} />
      ))}
    </div>
  );
}

interface WorkOrderItemProps {
  workorder: WorkOrder;
}

function WorkOrderItem({ workorder }: WorkOrderItemProps) {
  const navigate = useNavigate();

  return (
    <ListButton
      onClick={() => {
        navigate({
          to: '/workorders/$workorderId',
          params: { workorderId: workorder.id },
        });
      }}
      columnLeft={
        <>
          <div className="mb-2 flex pb-2">
            <h4 className="flex flex-row items-center gap-2 text-text-700">
              <IconGoogleDocs className="size-4" />
              {workorder.title}
            </h4>
            <span className="ml-2 text-text-600">
              #{workorder.title_suffix}
            </span>
          </div>
          <div className="flex-1 overflow-hidden text-ellipsis text-nowrap text-left text-sm text-text-600">
            {workorder.entities.map((entityId, i) => (
              <EntityName
                key={entityId}
                id={entityId}
                trailingComma={i < workorder.entities.length - 1}
              />
            ))}
          </div>
        </>
      }
      columnRight={
        <>
          {workorder.assignee && (
            <div className="flex h-auto w-full items-center justify-end">
              <span className="text-nowrap rounded-md bg-background-200 px-2 py-1 text-sm text-text-600">
                <UserName id={workorder.assignee} />
              </span>
            </div>
          )}
        </>
      }
    />
  );
}
