import { Outlet, createFileRoute } from '@tanstack/react-router';
import { NavBar } from '../components/NavBar';

export const Route = createFileRoute('/_layout')({
  component: Layout,
});

function Layout() {
  return (
    <div className="flex size-full flex-col items-center">
      <main className="flex max-h-full w-full max-w-full grow flex-col overflow-auto px-2 sm:px-8 md:px-16">
        <Outlet />
      </main>
      <NavBar />
    </div>
  );
}
