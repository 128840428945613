import { UseControllerProps, useController } from 'react-hook-form';
import { FormValues } from '../EntityPropertyList';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/select';
import { FormattedMessage } from 'react-intl';

export function EnumInput({
  options,
  ...props
}: UseControllerProps<FormValues> & { options: string[] }) {
  const { field } = useController(props);

  return (
    <Select
      value={(field.value as string) || ''}
      onValueChange={(value) => field.onChange(value === 'null' ? null : value)}
    >
      <SelectTrigger className="min-h-12 gap-2 px-4">
        <SelectValue
          placeholder={<FormattedMessage defaultMessage="Inget värde" />}
        />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="null">
          <FormattedMessage defaultMessage="Inget värde" />
        </SelectItem>
        {options.map((option) => (
          <SelectItem key={option} value={option}>
            {option}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
