import {
  createFileRoute,
  useNavigate,
  useRouter,
} from '@tanstack/react-router';
import { useVirtualizer } from '@tanstack/react-virtual';
import { FormattedMessage, useIntl } from 'react-intl';
import Input from '@/components/core/Input';
import { Calendar } from '../../components/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../components/popover';
import { IconCalendar, IconMapPin, IconPlus, IconXmark } from '@allbin/icons';
import Button from '@/components/core/Button';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Check } from 'lucide-react';
import Fuse from 'fuse.js';
import { useEntities } from '../../hooks/useEntities';
import { Spinner } from '@/components/Spinner';
import { Entity } from '../../types/entities';
import { collator } from '../../utils/collator';
import IconButton from '@/components/core/IconButton';
import {
  WorkOrderRequest,
  useWorkOrderCreate,
} from '../../hooks/useWorkOrders';
import { useEntityTypes } from '../../hooks/useEntityTypes';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { DateTime } from 'luxon';
import { useCurrentUser } from '../../hooks/useUsers';
import { PageHeader } from '../../components/PageHeader';
import { toast } from '@/hooks/useToast';

const maxEntities = 100;

const createWorkOrderSearch = z.object({
  title: z.string().optional(),
  description: z.string().optional(),
  entities: z.array(z.string()).optional(),
});

export const Route = createFileRoute('/_layout/create_workorder')({
  component: CreateWorkOrder,
  validateSearch: createWorkOrderSearch,
});

function CreateWorkOrder() {
  const { history } = useRouter();
  const search = Route.useSearch();
  const intl = useIntl();
  const navigate = useNavigate();
  const user = useCurrentUser();
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const entityTypes = useEntityTypes();
  const createWorkOrder = useWorkOrderCreate();

  const entities = useEntities();
  const sortedEntities = useMemo(
    () =>
      (entities.data || []).sort((a, b) =>
        collator.compare(a.full_name, b.full_name),
      ),
    [entities.data],
  );

  const schema = z.object({
    title: z
      .string()
      .min(1, intl.formatMessage({ defaultMessage: 'Måste fyllas i' })),
    due_at: z.date().nullable(),
    entities: z
      .string()
      .array()
      .max(
        maxEntities,
        intl.formatMessage(
          {
            defaultMessage: 'Max {maxEntities} hållplatser',
          },
          { maxEntities: maxEntities },
        ),
      ),
    description: z.string(),
    entity_type_id: z.string(),
    state: z.enum([
      'created',
      'blocked',
      'accepted',
      'completed',
      'rejected',
      'approved',
      'cancelled',
    ]),
    tags: z.array(z.string()),
    contractors: z.array(z.string()),
  });
  type FormValues = z.infer<typeof schema>;

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors, isDirty, isValid, isSubmitting },
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      title: search.title || '',
      due_at: null,
      entities: search.entities || [],
      description: search.description || '',
      entity_type_id: '',
      state: 'created',
      tags: [],
      contractors: user.data?.contractors?.map((c) => c.id) || [],
    } as FormValues,
  });

  useEffect(() => {
    // Set form values from external sources
    setValue(
      'entity_type_id',
      entityTypes.data?.find((t) => t.name === 'stop')?.id || '',
    );
    setValue('contractors', user.data?.contractors?.map((c) => c.id) || []);
  }, [entityTypes.data, setValue, user.data]);

  const onSubmit: SubmitHandler<FormValues> = useCallback(
    async (data) => {
      const due_at = data.due_at && DateTime.fromJSDate(data.due_at);

      const request: WorkOrderRequest = {
        ...data,
        state: data.state,
        entity_changesets: {},
        due_at: due_at?.isValid ? due_at : undefined,
      };

      try {
        const result = await createWorkOrder.mutateAsync(request);
        navigate({
          to: '/workorders/$workorderId',
          params: { workorderId: result.id },
        });
      } catch (error) {
        toast.error({
          title: 'Failed to create workorder',
          description: (error as Error).message,
        });
        console.error('error creating workorder', error);
      }
    },
    [createWorkOrder, navigate],
  );

  if (user.isLoading || entityTypes.isLoading || entities.isLoading) {
    return (
      <div className="mt-16 flex w-full justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <PageHeader
        title={<FormattedMessage defaultMessage="Ny arbetsorder" />}
      />
      <form
        className="relative flex grow flex-col"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="mt-[32px] grid grid-cols-1 gap-4 lg:grid-cols-2">
          <Input
            id="title"
            type="text"
            label={<FormattedMessage defaultMessage="Titel*" />}
            error={errors.title?.message}
            placeholder="Skriv en titel"
            disabled={isSubmitting}
            className="mb-4"
            {...register('title', { required: true })}
          />
          <div className="relative">
            <label
              htmlFor="end_date"
              className="absolute left-0 top-[-24px] mb-1 block text-sm font-medium leading-6 text-primary-900"
            >
              <FormattedMessage defaultMessage="Slutdatum" />
            </label>
            <Controller
              name="due_at"
              control={control}
              render={({ field }) => (
                <Popover open={datePickerOpen} onOpenChange={setDatePickerOpen}>
                  <PopoverTrigger asChild>
                    <Button
                      id="end_date"
                      className="w-full"
                      disabled={isSubmitting}
                    >
                      <IconCalendar className="mr-2 size-4" />
                      {field.value
                        ? new Intl.DateTimeFormat('sv-SE', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                          }).format(field.value)
                        : 'Välj datum'}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0">
                    <Calendar
                      mode="single"
                      required={false}
                      selected={field.value ?? undefined}
                      onSelect={(date) => {
                        field.onChange(date);
                        // setDate(date);
                        setDatePickerOpen(false);
                      }}
                      initialFocus
                    />
                  </PopoverContent>
                </Popover>
              )}
            />
          </div>
          <div className="mt-5">
            <Input
              type="multiline"
              id="description"
              rows={5}
              label={<FormattedMessage defaultMessage="Arbetsinstruktioner" />}
              placeholder="Beskriv vad som ska göras"
              disabled={isSubmitting}
              {...register('description')}
            />
          </div>
          <Controller
            name="entities"
            control={control}
            render={({ field }) => (
              <EntitySelector
                value={field.value}
                onChange={(value) => {
                  field.onChange(value);
                  field.onBlur();
                }}
                options={sortedEntities}
                disabled={isSubmitting}
                isLoading={entities.isLoading}
                error={errors.entities?.message}
              />
            )}
          />
        </div>
        <div className="sticky bottom-0 col-span-2 flex size-full items-end justify-end gap-2 border-t border-background-100 bg-background-50 py-2">
          <Button
            type="button"
            onClick={() => history.back()}
            disabled={isSubmitting}
          >
            <FormattedMessage defaultMessage="Avbryt" />
          </Button>
          <Button
            type="submit"
            variant="filled"
            disabled={!isDirty || !isValid || isSubmitting}
            startIcon={isSubmitting ? <Spinner /> : <IconPlus />}
          >
            <FormattedMessage defaultMessage="Skapa" />
          </Button>
        </div>
      </form>
    </>
  );
}

interface EntitySelectorProps {
  value: string[];
  onChange: (value: string[]) => void;
  options: Entity[];
  isLoading: boolean;
  disabled?: boolean;
  error?: string;
}

function EntitySelector({
  value,
  onChange,
  options,
  isLoading,
  disabled,
  error,
}: EntitySelectorProps) {
  const intl = useIntl();
  const [search, setSearch] = useState('');
  const fuse = useMemo(
    () =>
      new Fuse(options, {
        keys: ['full_name', 'entity_group'],
        threshold: 0.3,
        shouldSort: false,
      }),
    [options],
  );

  const filteredOptions = useMemo(() => {
    if (!search) {
      const [selected, rest] = options.reduce(
        (acc, entity) => {
          if (value.includes(entity.id)) {
            acc[0].push(entity);
          } else {
            acc[1].push(entity);
          }
          return acc;
        },
        [[], []] as [Entity[], Entity[]],
      );

      return [...selected, ...rest];
    }

    return fuse
      .search(search)
      .map((item) => item.item)
      .slice(0, 20);
  }, [fuse, options, search, value]);

  const parentRef = useRef<HTMLDivElement>(null);
  const virtualizer = useVirtualizer({
    count: filteredOptions.length,
    estimateSize: () => 40,
    getScrollElement: () => parentRef.current,
  });

  const handleEntityClick = useCallback(
    (entity: string) => {
      if (disabled) return;
      if (value.includes(entity)) {
        onChange(value.filter((id) => id !== entity));
      } else {
        onChange([...value, entity]);
      }
    },
    [onChange, value, disabled],
  );

  if (isLoading) {
    return (
      <div className="flex items-center gap-2 text-text-400">
        <Spinner className="size-5" />
        <FormattedMessage defaultMessage="Laddar hållplatser..." />
      </div>
    );
  }

  return (
    <div className="flex-1 overflow-hidden">
      <div className="relative mt-5 ">
        <Input
          id="entities"
          label={
            <>
              <FormattedMessage defaultMessage="Hållplatser" />
              {value.length > 0 && (
                <span className="text-text-700"> ({value.length})</span>
              )}
            </>
          }
          placeholder={intl.formatMessage({
            defaultMessage: 'Sök efter hållplats',
          })}
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          startAdornment={<IconMapPin className="size-4" />}
          disabled={disabled}
          error={error}
        />
        {search && (
          <IconButton
            className="absolute bottom-0 right-0"
            variant="ghost"
            onClick={() => setSearch('')}
            disabled={disabled}
            icon={<IconXmark />}
          />
        )}
      </div>
      <div className="relative">
        <div ref={parentRef} className="max-h-64 flex-1 overflow-auto">
          <ul
            className="relative"
            style={{ height: virtualizer.getTotalSize() }}
          >
            {virtualizer.getVirtualItems().map((virtualItem) => (
              <li
                key={virtualItem.key}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: `${virtualItem.size}px`,
                  transform: `translateY(${virtualItem.start}px)`,
                }}
              >
                <button
                  type="button"
                  className="flex h-10 w-full items-center gap-2 rounded px-2 text-left text-text-700 hover:bg-primary-100"
                  onClick={() =>
                    handleEntityClick(filteredOptions[virtualItem.index].id)
                  }
                  disabled={disabled}
                >
                  {value.includes(filteredOptions[virtualItem.index].id) ? (
                    <Check className="size-4" />
                  ) : (
                    <div className="size-4" />
                  )}
                  <span className="grow">
                    {filteredOptions[virtualItem.index].full_name}
                  </span>
                  <span className="mr-6 text-sm text-text-600">
                    {filteredOptions[virtualItem.index].entity_group}-
                    {filteredOptions[virtualItem.index].stop_letter}
                  </span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
